h1 {
    line-height: 90%;
}

h2 {
    line-height: 120%;
}

h3,
h4,
h5,
h6 {
    line-height: 150%;
}

.line-90 * {
    line-height: 90%;
}

.line-120 * {
    line-height: 120%;
}
