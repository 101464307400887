@use './../bootstrap/variables' as *;

.aldryn-newsblog-pagination {
    align-items: center;
    display: flex;
    justify-content: center;

    li {
        list-style-type: none;
        margin: 10px;
    }

    a,
    li > span {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .active {
        span {
            text-decoration: underline;
        }
    }

    .badge {
        display: none;
    }
}

.aldryn-newsblog-pager {
    padding-top: 50px;

    @media (min-width: 992px) {
        padding-top: 100px;
    }

    .pager {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media (min-width: 992px) {
            margin-left: 41.66666667%;
        }
    }

    li {
        list-style-type: none;
        padding: 1rem;

        @media (min-width: 768px) {
            padding: 0;
        }
    }
}

.djangocms-newsblog-article-detail {
    .pagination {
        padding-top: 50px;

        @media (min-width: 992px) {
            padding-top: 100px;
        }

        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media (min-width: 992px) {
            margin-left: 41.66666667%;
        }

        li {
            list-style-type: none;
            padding: 1rem;

            @media (min-width: 768px) {
                padding: 0;
            }
        }

        li.previous-page a::before {
            content: "←";
            left: -0.2em;
            position: relative;
        }
        li.next-page a::after {
            content: "→";
            right: -0.2em;
            position: relative;
        }
        li.back-to-overview {
            &::before {
                content: "–";
                left: -0.2em;
                position: relative;
            }
            &::after {
                content: "–";
                right: -0.2em;
                position: relative;
            }
        }
    }
}
