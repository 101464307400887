@use './../bootstrap/variables' as *;

main,
footer {
    overflow: hidden;
}

@media (min-width: 1450px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}

figure {
    margin: 0;
}

.mw-100-img img {
    max-width: 100%;
}

.button-wtih-icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding: 0.9rem 2rem;

    @media (min-width: 768px) {
        padding: 0.7rem 2rem;
    }

    > * {
        font-size: 1rem;
        margin: 0;

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    img {
        padding-left: 1rem;
    }
}

.button-more-with {
    width: 100%;

    @media (min-width: 768px) {
        width: 385px;
    }
}

.mb-n-0 * {
    margin-bottom: 0;
}

.lang {
    text-transform: uppercase;

    &.active {
        display: none;
    }
}

.underline-primary > * {
    background: linear-gradient(360deg, $primary 28%, transparent 28%);
    display: inline;
    letter-spacing: -1.52px;
}

.font-400-inter,
.font-400-inter * {
    font-family: Inter-Regular, sans-serif;
    font-weight: 400;
}

strong,
.font-700-inter,
.font-700-inter * {
    font-family: Inter-Bold, sans-serif;
    font-weight: 700;
}

.font-400-SourceCodePro,
.font-400-SourceCodePro * {
    font-family: SourceCodePro-SemiBold, sans-serif;
    font-weight: 400;
}

.font-700-SourceCodePro-Bold,
.font-700-SourceCodePro-Bold * {
    font-family: SourceCodePro-Bold, sans-serif;
    font-weight: 700;
}

.images-line-1 img {
    margin-left: -24%;
}

.images-line-2 img {
    margin-left: -179%;

    @media (min-width: 1200px) {
        margin-left: -130%;
    }

    @media (min-width: 1399px) {
        margin-left: -97%;
    }

    @media (min-width: 1450px) {
        margin-left: -84%;
    }
}

.w-100-img img {
    width: 100%;
}

.wm-100-img img {
    max-width: 100%;
}

.btn-xxl-width {
    min-width: 100%;

    @media (min-width: 567px) {
        min-width: 416px;
    }
}

.img-absolute {
    position: relative;

    > img {
        display: none;
        position: absolute;

        @media (min-width: 992px) {
            display: block;
        }
    }
}

.accommodations {
    > img:first-of-type {
        top: 25%;
    }

    > img:last-of-type {
        bottom: 25%;
        right: 0;
    }
}

.tab-people > * {
    border-right: 1px solid $primary;
    line-height: 1;
    padding: 0 16px;

    &:first-of-type {
        padding-left: 0;
    }

    &:last-of-type {
        border-right: 0;
    }
}

.tab-link > a {
    border-right: 2px solid $primary;
    line-height: 1;
    padding: 0 16px;

    &:first-of-type {
        padding-left: 0;
    }

    &:last-of-type {
        border-right: 0;
    }
}

.nav.nav-tabs {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;

    .nav-link {
        border: 0;
        border-radius: 0;
        box-shadow: 0;
        color: $secondary;
        font-size: 0.9rem;
        margin: 20px 15px;
        padding: 14px 30px;
    }

    .active {
        background-color: $secondary;
        border: 0;
        box-shadow: 0;
        color: $white;
        font-family: Inter-Bold, sans-serif;
    }
}

.tab-content {
    article {
        border-bottom: 1px solid $info;
        padding: 18px 0;

        aside > *:last-of-type {
            padding-bottom: 0;
        }

        & > aside:first-of-type > p,
        aside aside:last-of-type > p {
            margin-bottom: 0;
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }
}

.text-underline,
.text-underline * {
    text-decoration: underline;
}

.list {
    ul {
        padding-left: 1.3rem;
    }

    li {
        list-style-image: url('./../img/list.svg');
    }
}

.mb-p-6 > p {
    margin-bottom: 2rem;
}

table {
    font-size: 1rem;
    width: 100%;

    tr {
        border-bottom: 1px solid $info;
        vertical-align: top;
    }

    td {
        padding: 0.7rem 0;

        &:first-of-type > * {
            color: $primary;
            margin-right: 30px;
        }
    }

    td:nth-child(2) {
        padding-right: 2rem;
    }

    td:nth-child(2),
    td:nth-child(3) {
        width: 50%
    }

    h4 {
        margin-bottom: 1.5rem;
    }

    h6 {
        margin-top: 0.5rem;
    }
}

.overlay-img-1 {
    position: relative;

    img {
        position: absolute;
    }

    img:first-of-type {
        left: 75px;
    }
}

.overlay-img-2 {
    position: relative;

    img {
        display: none;
        position: absolute;

        @media (min-width: 992px) {
            display: block;
        }
    }

    img:first-of-type {
        height: 529px;
        object-fit: cover;
        width: 550px;

        @media (min-width: 992px) and (max-width: 1119px) {
            left: -19%;

        }

        @media (min-width: 1120px) and (max-width: 1325px) {
            left: -15%;
        }
    }
}

.response-img {
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

p a {
    text-decoration: underline;
}
