@use "sass:math";

@font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: 'SourceCodePro-SemiBold';
    src: url('../fonts/SourceCodePro-SemiBold.ttf');
}

@font-face {
    font-family: 'SourceCodePro-Bold';
    src: url('../fonts/SourceCodePro-Bold.ttf');
}

// Color system
// scss-docs-start color-variables
$primary: #1ec800;
$secondary: #333;
$light: #f3fff1;
$white: #fff;
$info: #bababa;
$border: #d9d9d9;
$danger: #ce1126;

// scss-docs-start theme-colors-map
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'light': $light,
    'white': $white,
    'info': $info,
    'border': $border,
    'danger': $danger,
);

// Spacing
// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
    0: 0,
    1: 0.25rem,
    // 4
    2: 0.5rem,
    // 8
    3: 0.75rem,
    // 12
    4: $spacer * 1.125,
    // 18
    5: $spacer * 1.25,
    // 20
    6: $spacer * 1.875,
    // 30
    7: $spacer * 2.5,
    // 40
    8: $spacer * 3.125,
    // 50
    9: $spacer * 3.75,
    // 60
    10: $spacer * 5,
    // 80
    11: $spacer * 6.25,
    // 100
    12: $spacer * 7.25,
    // 120
);

@function space($space) {
    @return map-get($spacers, $space);
}

// Typography
$font-family-base: Inter-Regular, sans-serif;
$font-size-base: 1rem;
$headings-line-height: 1.5;
$headings-font-weight: 700;
$headings-font-family: SourceCodePro-Bold, sans-serif;
$h1-font-size: $font-size-base * 6.625; //106
$h2-font-size: $font-size-base * 2.375; //38
$h3-font-size: $font-size-base * 1.625; //26
$h4-font-size: $font-size-base * 1.5; // 24
$h5-font-size: $font-size-base * 1.25; // 20
$h6-font-size: $font-size-base * 1.125; //18

// Body
// Settings for the `<body>` element.
$body-color: $secondary;
$body-bg: $white;

// Style anchor elements.
$link-color: $secondary;
$link-decoration: none;

// scss-docs-start font-sizes
$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
);

// scss-docs-start display-headings
$display-font-sizes: (
    1: 106px,
    2: 38px,
    3: 30px,
    4: 26px,
    5: 24px,
    6: 20px,
    7: 18px,
);

// Buttons
// scss-docs-start btn-variables
$btn-color: $white;
$btn-padding-y: 0.95rem;
$btn-padding-x: 1.875rem;
$input-btn-font-family: Inter-Bold, sans-serif;
$btn-font-weight: 700;
$btn-font-size: 20px;
$btn-line-height: 1;

$btn-padding-y-sm: 0.6rem;
$btn-padding-x-sm: 1rem;
$btn-font-size-sm: 1rem;

$btn-padding-y-lg: 1.45rem;
$btn-padding-x-lg: 1.875rem;
$btn-font-size-lg: 24px;

$btn-border-width: 0;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// Border
// $border-color: $gray-200;
// $border-width: 1px;
// $border-style: solid;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$input-color: $border;
$input-border-color: $border;
$input-border-width: 1px;
$input-box-shadow: none;
