
.btn-primary {
    @include button-variant( // bg
        $primary,
        // border-color
        $primary,
        // color
        $white,
        // hover:bg
        $primary,
        // hover:border-color
        $primary,
        // hover:color
        $white,
        // active:bg
        $primary,
        // active:border-color
        $primary,
        // active:color
        $white );
}

.btn-secondary {
    @include button-variant( // bg
        $secondary,
        // border-color
        $secondary,
        // color
        $white,
        // hover:bg
        $secondary,
        // hover:border-color
        $white,
        // hover:color
        $white,
        // active:bg
        $secondary,
        // active:border-color
        $secondary,
        // active:color
        $white);
}
