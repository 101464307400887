@use './../bootstrap/variables' as *;


.djangocms-newsblog-content {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .djangocms-newsblog-content {
        max-width: 540px;
      }
}

@media (min-width: 768px) {
    .djangocms-newsblog-content {
        max-width: 720px;
      }
}

@media (min-width: 992px) {
    .djangocms-newsblog-content {
        max-width: 960px;
    }
}

@media (min-width: 1450px) {
    .djangocms-newsblog-content {
        max-width: 1400px;
    }
}

// row
.aldryn.aldryn-newsblog.aldryn-newsblog-articles,
.aldryn-newsblog-latest-articles,
.aldryn-newsblog-list,
.djangocms-newsblog-article-list {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-0.5*var(--bs-gutter-x));
    margin-right: calc(-0.5*var(--bs-gutter-x));
    margin-top: calc(-1*var(--bs-gutter-y));
    padding: 2rem 0;

    @media (min-width: 992px) {
        padding-bottom: 3.8rem;
    }

    > article {
        flex: 0 0 100%;
        margin-top: var(--bs-gutter-y);
        max-width: 100%;
        padding-bottom: 20px;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-top: 20px;

        @media (min-width: 768px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media (min-width: 992px) {
            flex: 0 0 25%;
            max-width: 25%;
        }

        > h2 {
            border-bottom: 4px solid $primary;
            font-size: 1.625rem;
            padding-bottom: 0.5rem;

            @media (min-width: 768px) {
                min-height: 72px;
            }

            @media (min-width: 992px) {
                min-height: 106px;
            }

            @media (min-width: 1440px) {
                min-height: 72px;
            }
        }

        .lead {
            font-size: 1rem;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.aldryn-newsblog-detail,
.djangocms-newsblog-article-detail {
    padding-top: 50px;
    position: relative;

    @media (min-width: 992px) {
        padding-top: 100px;

        &::before {
            content: url('./../img/csnog-novinky.svg');
            left: -13%;
            position: absolute;
        }
    }

    .aldryn-newsblog-article,
    .article-detail {
        @media (min-width: 992px) {
            margin-left: 41.66666667%;
        }
    }
}
