@use './../bootstrap/variables' as *;

.gallery {
    margin-bottom: 3rem;
}

.gallery-row {
    align-items: center;
    display: flex;
    justify-content: space-between;

    margin: 1rem 0;

    @media screen and (max-width: 890px) {
        flex-direction: column;
    }
}

.gallery-view.image-hide {
    animation: 0.5s image-hide;
}

.gallery-view {
    align-items: center;
    display: flex;
    justify-content: center;

    animation: 0.5s image-show;
    background-color: rgba(0, 0, 0, 0.75);
    color: $white;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 9999;

    img {
        height: 90vh;
        object-fit: contain;
        width: 90%;
    }

    .gallery-close {
        background: url('./../img/gallery_close.svg') no-repeat center;
        border-radius: 50%;
        cursor: pointer;
        height: 35px;
        position: absolute;
        right: 2rem;
        top: 2rem;
        transition: all 0.2s ease-in-out;
        width: 35px;
    }

    .gallery-previus,
    .gallery-next {
        cursor: pointer;
        display: block;
        height: 70px;
        position: absolute;
        top: calc(50vh - 35px);
        transition: all 0.2s ease-in-out;
        width: 70px;
    }

    .gallery-previus {
        background: url('./../img/arrow-left.svg') no-repeat 17px center;
        left: 2.8rem;
        text-align: left;
    }

    .gallery-next {
        background: url('./../img/arrow-right.svg') no-repeat 17px center;
        right: 2rem;
        text-align: right;
    }

    .gallery-previus:hover,
    .gallery-next:hover,
    .gallery-close:hover {
        opacity: 1;
    }
}

.gallery-img.image-change {
    animation: 0.5s image-change;
}

.filer-gallery-item {
    padding-bottom: 1.5rem;

    img {
        height: 240px;
        max-width: 100%;
        object-fit: cover;

        @media (min-width: 634px) {
            height: 160px;
        }

        @media (min-width: 845px) {
            height: 142px;
        }

        @media (min-width: 1092px) {
            height: 92px;
        }

        @media (min-width: 1595px) {
            height: 138px;
        }
    }
}

@keyframes image-show {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes image-hide {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}

@keyframes image-change {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.96, 0.96, 0.96);
    }

    to {
        opacity: 1;
    }
}
